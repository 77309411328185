import * as React from 'react'

import { Paper, Stack, Typography } from '@mui/material'

import { Image, SupportMailLink } from '../components'
import { AppContext } from '../contexts'

const withoutSlackFormat = (input: string) => input.replace(/(_\*|\*_)/g, '')

type NotificationConfirmationProps = {
  message: string[]
  isPurchase?: boolean
}

export const NotificationConfirmation = ({
  message,
  isPurchase,
}: NotificationConfirmationProps) => {
  const { animatedLogo, appName } = React.useContext(AppContext)

  return (
    <Stack
      spacing={3}
      alignItems='center'
    >
      <Image
        src={animatedLogo}
        alt={`Logo ${appName} cambiante`}
        width='75%'
        duration={500}
      />
      <Typography
        variant='h5'
        textAlign='center'
      >
        {isPurchase ?
          'Estás a un paso de finalizar tu inversión, transfiere desde tu cuenta bancaria el monto comprometido.'
          : 'Tu solicitud fue notificada, pronto nos pondremos en contacto.'}
      </Typography>
      <Paper
        variant='outlined'
        sx={{ bgcolor: 'action.hover', px: 2, py: 1, textAlign: 'center' }}
      >
        {message.map((line, index) => (
          <Typography key={index}>{withoutSlackFormat(line)}</Typography>
        ))}
      </Paper>
      <Typography
        variant='subtitle1'
        textAlign='center'
      >
        Si necesitas ayuda, o tienes cualquier duda al respecto por favor
        contáctanos a través de nuestro correo de soporte:
      </Typography>
      <SupportMailLink />
    </Stack>
  )
}
