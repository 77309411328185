import * as React from 'react'

import { Notifications, Pending } from '@mui/icons-material'
import { Badge } from '@mui/material'

import type { UserNotification } from '../queries'

type UserNotificationsIconProps = {
  loading: boolean
  userNotifications?: UserNotification[]
}

export const UserNotificationsIcon = ({
  loading,
  userNotifications,
}: UserNotificationsIconProps) => {
  if (loading) {
    return <Pending />
  }

  return (userNotifications?.some(({ resolvedAt }) => !resolvedAt )) ? (
    <Badge
      color='error'
      badgeContent=' '
      variant='dot'
    >
      <Notifications />
    </Badge>
  ) : (
    <Notifications />
  )
}
