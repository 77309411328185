import * as React from 'react'

import { Tooltip } from '@mui/material'

import { Currency } from './currency'

import type { UserNotification } from '../queries'

type UserNotificationContentProps = {
  notification: UserNotification
}

export const UserNotificationContent = ({
  notification,
}: UserNotificationContentProps) => {
  const notificationType = notification.notificationType
  const parsedContent = JSON.parse(notification.content)

  if (notificationType === 'PURCHASE') {
    const [fromSymbol, fromAmount, toSymbol] = parsedContent
    return (
      <Tooltip title={`${fromAmount} ${fromSymbol}`}>
        <span>
          <Currency
            currency={fromSymbol}
            value={fromAmount}
          />
          {toSymbol && ` > ${toSymbol}`}
        </span>
      </Tooltip>
    )
  } else if (notificationType === 'SWAP') {
    const [fromSymbol, fromAmount, toSymbol] = parsedContent
    return (
      <Tooltip title={`${fromAmount} ${fromSymbol}`}>
        <span>
          <Currency
            currency={fromSymbol}
            value={fromAmount}
          />
          {' > '}
          {toSymbol}
        </span>
      </Tooltip>
    )
  } else if (notificationType === 'WITHDRAW_BANK') {
    const [symbol, amount] = parsedContent
    return (
      <Tooltip title={`${amount} ${symbol}`}>
        <span>
          <Currency
            currency={symbol}
            value={amount}
          />
        </span>
      </Tooltip>
    )
  }
}
